.navbartxtclr {
  color: white !important;
}
.navbar-dark .navbar-nav .nav-link {
  color: white !important;
  margin: 0 1.3rem;
  font-family: Arial, Helvetica, sans-serif;
}
.dropdown-menu.show {
  margin-top: 8px !important;
}
#collasible-nav-dropdown\ clrID {
  color: black !important;
}
.dropdown-menu {
  min-width: 300px !important;
}
.dropdown-item {
  white-space: break-spaces !important;
}
#responsive-navbar-nav
  > div
  > div.active.nav-item.show.dropdown
  > div
  > div.nav-item.show.dropdown
  > div {
  /* margin: 12px !important; */
  background-color: rgba(243, 240, 248, 0.959);
  overflow-y: scroll;
  height: 200px;
  width: 100%;
}
@media (max-width: 1375px) {
  .navbar-dark .navbar-nav .nav-link {
    margin: 0px !important;
  }
}

.AlphabetNav {
  //   position: fixed;
  //   top: 0;
  right: 0;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  // Shorthand for flex-wrap and flex-direction
  flex-flow: wrap row;
  padding: 0.5rem;
  background: ghostwhite;
}

.AlphabetNav a {
  display: flex;
  // Center align the letters within each box
  justify-content: center;
  padding: 0.5em;
  border-radius: 8px;
  font-size: 1.2em;
  line-height: 1;
  font-weight: 500;
  text-decoration: none;
  color: darkgray;
  cursor: pointer;
}

.AlphabetNav a:hover {
  background: #ddd;
  color: #444;
}

.AlphabetNav .active {
  background: #f46353;
  color: white;
}

/*
    Contact List
  */
.ContactList {
  padding: 10em 2rem 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.Contact {
  // Flexbox allows us to easily
  // align the avatar and the name
  display: flex;
  align-items: center;
  width: 90%;
}

.ContactList .Contact {
  margin-bottom: 1.5rem;
}

.Contact-avatar {
  margin: 0 1em 0 0;
  width: 30%;
  min-width: 60px;
  max-width: 100px;
  border-radius: 50%;
  background: #a7c031;
}

.Contact-name {
  margin: 0;
  font-size: 1.3em;
  font-weight: 500;
  color: darkgray;
}

// @media (min-width: 400px) {
//   .AlphabetNav {
//     top: 2vh;
//     right: 0;
//     height: 90vh;
//     width: 9em;
//   }

//   .AlphabetNav a {
//     // desired width - margins
//     width: calc(100% * 1 / 3 - 0.3em);
//   }

//   .ContactList {
//     padding-top: 2rem;
//   }
// }

// @media (min-width: 400px) and (min-height: 600px) {
//   .AlphabetNav {
//     width: 6em;
//   }

//   .AlphabetNav a {
//     width: calc(100% * 1 / 2 - 0.5em);
//     margin: 0.25em;
//   }
// }

// @media (min-width: 800px) {
//   // Change AlphabetNav to
//   // work horizontally
//   .AlphabetNav {
//     // top: 0;
//     display: flex;
//     align-items: flex-start;
//     flex-flow: nowrap row;
//     justify-content: space-around;
//     width: 100%;
//     height: auto;
//     background: ghostwhite;
//   }

//   .AlphabetNav a {
//     margin: 0.5em 0;
//     padding: 0.5em 0;
//   }

//   .ContactList {
//     padding-top: 6rem;
//   }

//   .Contact {
//     width: calc(100% * 1 / 3 - 2rem);
//   }
// }

@import url(https://fonts.googleapis.com/css?family=Roboto:300,500,400);

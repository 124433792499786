.Card {
  background-color: aquamarine;
}

@media (max-width: 600px) {
  .carousal {
    height: 200px !important;
  }
  .carousal img {
    height: 200px;
  }
}

.videoPlayer {
  max-width: 800px !important;
  /* width: 100%; */
  height: 300px !important;
}

@media (max-width: 600px) {
  .videoPlayer {
    max-width: 350px !important;
    height: 210px !important;
  }
}

.font {
  text-align: justify;
}
.font:hover {
  color: #1979c3;
}

@media (max-width: 600px) {
  .newsimg {
    height: 150px !important;
  }
}

.icons {
  font-size: 2.5rem !important;
  cursor: pointer !important;
  transition: all 0.3s ease-in-out !important;
}
.icons:hover {
  transition: all 0.3s ease-in-out !important;
  outline: none;

  transform: translateY(-0.5rem) !important;
}
.videoplayer {
  max-width: 400px !important;
  height: 210px !important;
}
.slick-prev:before,
.slick-next:before {
  color: black !important;
}

.banner {
  background-color: #cc0000;
}
.bannerfont {
  color: white;
  padding: 10px;
}
.text input {
  height: 40px;
  padding: 0px 14px;
}
.text label {
  height: 36px;
  top: -6px;
}
@media (max-width: 600px) {
  .btnnn {
    margin-top: 10px !important;
  }
  .carousal {
    height: 200px !important;
  }
  .mh {
    margin-top: 8px;
  }
  .videoplayer {
    max-width: 350px !important;
  }
  .videoplayerr {
    max-width: 350px !important;
  }
  .bannerfont {
    font-size: larger !important;
  }
}

.calender {
  font-size: smaller !important;
}
.tag {
  font-size: smaller !important;
  -ms-transform: rotate(360deg); /* IE 9 */
  transform: rotate(100deg);
}

.arrow {
  color: red;
}
.title:hover {
  color: #1979c3;
}
@media (max-width: 600px) {
  .Mobile {
    padding-left: 0px !important;
    margin: 0px !important ;
  }
  .Mobilet {
    padding-left: 0px !important;
    margin: 0px !important ;
    display: none;
  }
  .mobileviewpic {
    display: none !important;
  }
  .title {
    padding-left: 0px !important;
    font-size: medium;
    color: rgb(44, 101, 139);
  }
  .titleicon {
    display: none;
  }
  .jobtype {
    font-size: smaller !important;
  }
  .jobparagraph {
    font-size: smaller;
  }
  .cardAdjust {
    display: inherit !important;
  }
}

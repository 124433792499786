.cDiscription {
  white-space: nowrap;

  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}
.cName {
  cursor: pointer;
}
.cName:hover {
  color: red;
}
.cStyle:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
.btn {
  border: 1px solid red;
}
@media (max-width: 600px) {
  .cName {
    font-size: large;
  }
}

.ProfileCardTxt {
  margin-top: 10px !important;
}
.instagram {
  color: #e4405f;
  font-size: 40px !important;
}
.facebook {
  color: #3b5999;
  font-size: 40px !important;
}
.twitter {
  color: #55acee;
  font-size: 40px !important;
}

/* .options {
  margin-top: 20px !important;
  border: 1px solid gainsboro;
  border-radius: 5px;
  padding: 10px;
  color: gray;

  cursor: pointer;
}
.optags {

  margin-right: 10px;
}
.right {
  background-color: lawngreen;
  margin-top: 20px !important;
  border: 1px solid gainsboro;
  border-radius: 5px;
  padding: 10px;
  color: gray;

  cursor: pointer;
}
.wrong {
  background-color: red;
  margin-top: 20px !important;
  border: 1px solid gainsboro;
  border-radius: 5px;
  padding: 10px;
  color: gray;

  cursor: pointer;
} */

.app {
  background-color: #f8f9fa;
  width: 100%;
  min-height: 300px;
  height: min-content;
  margin-top: 20px;
  justify-content: center;
  align-items: center;

  /* border-radius: 15px; */
  padding: 20px;
  /* box-shadow: 10px 10px 42px 0px rgba(172, 171, 171, 0.75); */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  /* display: grid; */
  justify-content: space-evenly;
}

.score-section {
  display: grid;
  justify-content: center;
  margin-top: 16%;
  /* align-items: center; */
  font-size: 24px;
  color: rgb(0, 0, 0);
}

/* QUESTION/TIMER/LEFT SECTION */
.question-section {
  width: 100%;
  font-size: 20px;
  position: relative;
  color: rgb(0, 0, 0);
}

.question-count {
  margin-bottom: 20px;
}

.question-count span {
  font-size: 28px;
}

.question-text {
  margin-bottom: 12px;
}

.timer-text {
  background: rgb(230, 153, 12);
  padding: 15px;
  margin-top: 20px;
  margin-right: 20px;
  border: 5px solid rgb(255, 189, 67);
  border-radius: 15px;
  text-align: center;
}

/* ANSWERS/RIGHT SECTION */
.answer-section {
  width: 100%;
  display: flex;
  flex-direction: column;

  justify-content: space-between;
}

.quizBtn {
  width: 100%;
  font-size: 20px;
  color: #000000;
  background-color: #f5f3f4;
  border-radius: 5px !important;
  display: flex;
  margin-top: 15px !important;
  padding: 5px !important;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid #787e83;
  cursor: pointer;
}

.correct {
  background-color: #2f922f;
}

.incorrect {
  background-color: #ff3333;
}

button:hover {
  background-color: #aeb3c5;
}

button:focus {
  outline: none;
}

button svg {
  margin-right: 5px;
}
.scrollbar {
  margin-left: 30px;
  float: left;
  height: 600px;
  padding: 10px;
  width: 45%;
  background: #f6f8f8;
  overflow-y: scroll;
  margin-bottom: 25px;
}
#style-8::-webkit-scrollbar-track {
  border: 1px solid black;
  background-color: #f5f5f5;
}

#style-8::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

#style-8::-webkit-scrollbar-thumb {
  background-color: #000000;
}
@media (max-width: 600px) {
  .scrollbar {
    width: 100%;
    margin-left: 0px !important;
  }
}

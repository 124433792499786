
$black: #2A363B;
$red: #1979C3;
$red-dark: #C06C84;
$orange: #4393AF;
$yellow: #FECEAB;
$white: #FFF;

@mixin mobile {
  @media screen and (max-width: 1000px) {
    @content; } }

.menu {
  background: #1979C3;
  height: 4rem;
  z-index: 100;

  ol {
    list-style-type: none;

    margin: 0 auto;
    padding: 0; }

  > ol {

    padding: 0 2rem;
    display: flex;

    > .menu-item {
      flex: 1;
      padding: 0.75rem 0;
      > .menu-item a {}


      &:after {
        content: '';
        position: absolute;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        bottom: 5px;
        left: calc(50% - 2px);
        background: $yellow;
        will-change: transform;
        transform: scale(0);
        transition: transform 0.2s ease; }

      &:hover:after {
        transform: scale(1); } } }

  &-item {
    position: relative;

    text-align: center;

    a {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: block;
      color: $white; }

    @at-root .sub-menu & {
      padding: 0.35rem 0;
      background: $red;
      opacity: 0;
      transform-origin: bottom;
      animation: enter 0.2s ease forwards;

      @for $n from 1 through 3 {
        &:nth-child(#{$n}) {
          animation-duration: 0.2s + 0.1s * ($n - 1);
          animation-delay: 0.1s * ($n - 1); } }

      &:hover {
        background: $orange; }

      a {
        padding: 0 0.75rem; }

      @include mobile {
        background: $red-dark; } } }

  @include mobile {
    position: relative;

    &:after {
      content: '';
      position: absolute;
      top: calc(50% - 2px);
      right: 1rem;
      width: 30px;
      height: 4px;
      background: $white;
      box-shadow: 0 10px $white, 0 -10px $white; }

    > ol {
      display: none;
      background: $red;
      flex-direction: column;
      justify-content: center;

      animation: fade 0.2s ease-out;

      > .menu-item {
        flex: 0;
        opacity: 0;
        animation: enter 0.3s ease-out forwards;

        @for $n from 1 through 5 {
          &:nth-child(#{$n}) {
            animation-delay: 0.1s * ($n - 1); } }

        & + .menu-item {
          margin-top: 0.75rem; }

        &:after {
          left: auto;
          right: 1rem;
          bottom: calc(50% - 2px); }

        &:hover {
          z-index: 1; } } }

    &:hover {
      > ol {
        display: flex; }

      &:after {
        box-shadow: none; } } } }

.sub-menu {
  position: absolute;

  top: 100%;
  left: 0;
  display: none;
  z-index: 100;

  @at-root .menu-item:hover > & {
    display: block; }

  @include mobile {
    width: 100vw;
    left: -2rem;
    top: 50%;
    transform: translateY(-50%); } }


html, body {
  font-size: 16px;

  margin: 0;
  /* background: $black */ }

* {
  box-sizing: border-box;

  &:before, &:after {
    box-sizing: inherit; } }

a {
  text-decoration: none; }

@keyframes enter {
  from {
    opacity: 0;
    transform: scaleY(0.98) translateY(10px); }
  to {
    opacity: 1;
    transform: none; } }

@keyframes fade {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

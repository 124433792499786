.bod {
  display: flex;
  justify-content: center;
  align-items: center;
}

.LoaderBalls {
  width: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__item {
    // .LoaderBalls__wrapper__item
    $anim-drt: 0.4s;
    $anim-ease: cubic-bezier(0.6, 0.05, 0.15, 0.95);
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #1979c3;

    &:nth-child(1) {
      animation: bouncing $anim-drt alternate infinite $anim-ease;
    }

    &:nth-child(2) {
      animation: bouncing $anim-drt $anim-drt/4 alternate infinite $anim-ease
        backwards;
    }

    &:nth-child(3) {
      animation: bouncing $anim-drt $anim-drt/2 alternate infinite $anim-ease
        backwards;
    }
  }
}

@keyframes bouncing {
  0% {
    transform: translate3d(0, 10px, 0) scale(1.2, 0.85);
  }

  100% {
    transform: translate3d(0, -20px, 0) scale(0.9, 1.1);
  }
}

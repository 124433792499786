.socialbtn {
  display: flex;
  justify-content: center;
}

.btnFacebook {
  width: 200px;
  height: 35px;
  border-radius: 4px;
  background: #3b5998;
  color: white;
  border: 0px transparent;
  text-align: center;
  margin: 5px;
  /* display: inline-block; */
}
.btnGoogle {
  margin: 5px !important;
  width: 200px !important;
  height: auto !important;
  border-radius: 4px !important;
  background: white !important;
  color: black !important;
  border: 0px transparent !important;
  text-align: center !important;
  /* box-shadow: none !important; */
}
.btnFacebook:hover {
  background: #3b5998;
  opacity: 0.6;
}
.btnGoogle:hover {
  background: #db3236;
  opacity: 0.6;
}

@media only screen and (max-width: 399px) {
  .btnFacebook,
  .btnGoogle {
    width: 100%;
  }
}
